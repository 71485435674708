import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useMemo, useState } from 'react'
import { paymentApi } from '../../../../services/api/payments/payments.api'
import { EmployeeTransactionsIds, ErrorResponse, LeadingMethod, LeadingMethodDto, OrderResponse, PaymentMethod } from '../../../../services/api/payments/payments.types'
import { createPayByCardOrder, createPayByLinkOrder } from '../../../../utils/create-order'
import { getTransactionsSummary, TransactionsSummary } from '../../../../utils/get-transacions-summary'
import CyclicPayments from '../../cyclic-payment/cyclic-payment'
import HybridPayment from '../../hybrid-payment/hybrid-payment'
import { PaymentConfigParams } from './transactions-analyzer.types'
import ErrorCard from "../../../error-card";
import Loading from "../../../loading";
import PayUWrapper from '../../payu-wrapper/payu-wrapper'
import { useTranslation } from 'react-i18next'
import { useRequestPolling } from '../../../../hooks/useRequestPolling/useRequestPolling'

const MAX_RETRY_TO_GET_LEADING_METHOD = Number(process.env.REACT_APP_MAX_RETRY_TO_GET_LEADING_METHOD) || 10
const REFETCH_INTERVAL_TO_GET_LEADING_METHOD = Number(process.env.REACT_APP_REFETCH_INTERVAL_TO_GET_LEADING_METHOD) || 1000

export interface TransactionsAnalyzerProps {
  paymentConfig: PaymentConfigParams
}

const TransactionsAnalyzer: React.FC<TransactionsAnalyzerProps> = ({ paymentConfig }) => {

  const { t } = useTranslation()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null)
  const [isCustomResponseError, setIsCustomResponseError] = useState<boolean>(false)

  const { data, isError: isResponseError, refetch } = useQuery<LeadingMethodDto, ErrorResponse>({
    retry: 1,
    enabled: false,
    queryKey: ['getLeadingMethod'],
    queryFn: () => {
      const { transactions } = paymentConfig
      const employeeTransactionsIds: EmployeeTransactionsIds = { transactions }
      return paymentApi.getLeadingMethod(employeeTransactionsIds)
    },
    onSuccess(data) {
      const { isReadyToPay, leadingMethod, redirectUri } = data;

      if (leadingMethod === 'REDIRECT' && redirectUri) {
        window.location.href = redirectUri;
        return;
      }

      if (!isReadyToPay && leadingMethod !== 'INCOMPATIBLE') {
        retry()
      }
    }
  })

  const { isMaxNumberOfRefetch, retry } = useRequestPolling(refetch, {
    MAX_RETRY: MAX_RETRY_TO_GET_LEADING_METHOD,
    REFETCH_INTERVAL: REFETCH_INTERVAL_TO_GET_LEADING_METHOD
  })

  const transactionSummary = useMemo<TransactionsSummary>(() => getTransactionsSummary(data?.transactions || []), [data])

  const isReadyToPay: boolean = Boolean(data?.isReadyToPay)
  const leadingMethod: LeadingMethod | null = data?.leadingMethod || null
  const { transactionsIds } = transactionSummary
  const { origin } = paymentConfig

  // Automatically pay by link
  useEffect(() => {
    (async () => {
      const isSinglePaymentMethod: boolean = isReadyToPay && leadingMethod === 'SINGLE';
      if (isSinglePaymentMethod) {
        await payByLink()
      }
    })()
  }, [data, transactionSummary])

  useEffect(() => {
    const paymentMethodKeys: PaymentMethod[] = ["SINGLE", "CYCLIC", "HYBRID"];

    if (leadingMethod && paymentMethodKeys.includes(leadingMethod as any)) {
      setSelectedPaymentMethod(leadingMethod as PaymentMethod)
    }
  }, [data])

  const payByLink = async () => {
    try {
      const orderResponse: OrderResponse = await createPayByLinkOrder(transactionsIds, origin)
      location.href = orderResponse.redirectUri;
    } catch (error) {
      // TODO: add an error logging mechanism
      setIsCustomResponseError(true)
    }
  }

  const payByCard = async (payuToken: string) => {
    try {
      const orderResponse: OrderResponse = await createPayByCardOrder(transactionsIds, origin, payuToken)
      location.href = orderResponse.redirectUri;
    } catch (error) {
      // TODO: add an error logging mechanism
      setIsCustomResponseError(true)
    }
  }

  const selectPaymentMethod = (): JSX.Element => {
    // If isPaymentConfigurationHybrid flag have value true, user can change payment method
    // Eg using back button in card view
    const isPaymentConfigurationHybrid: boolean = leadingMethod === 'HYBRID'
    switch (selectedPaymentMethod) {
      case 'CYCLIC':
        return <PayUWrapper><CyclicPayments transactionsSummary={transactionSummary} payByCard={payByCard} setSelectedPaymentMethod={isPaymentConfigurationHybrid ? setSelectedPaymentMethod : undefined} /></PayUWrapper>
      case 'HYBRID':
        return <PayUWrapper><HybridPayment transactionsSummary={transactionSummary} setSelectedPaymentMethod={setSelectedPaymentMethod} payByLink={payByLink} /></PayUWrapper>
      case 'SINGLE':
      default:
        return <></>
    }
  }


  if (isResponseError || isCustomResponseError) {
    // TODO: add an error logging mechanism

    const messageContent: string[] = [
      t('Ups! Coś poszło nie tak.'),
      t('Sprawdź parametry transakcji i spróbuj ponownie.')
    ]
    const messageToShow: string = messageContent.join('<br />')
    return <ErrorCard message={messageToShow} />;
  }

  if (leadingMethod === 'INCOMPATIBLE') {
    // TODO: add an error logging mechanism
    const messageContent: string[] = [
      t('Przepraszamy, ale nie mogliśmy sfinalizować Twojej transakcji.'),
      t('Podane transakcje nie mogą być opłacane wspólnie.')
    ]
    const messageToShow: string = messageContent.join('<br />')
    return <ErrorCard message={messageToShow} />
  }

  if (leadingMethod === 'BLOCKED') {
    // TODO: add an error logging mechanism
    const messageContent: string[] = [
      t('Przepraszamy, ale nie mogliśmy zrealizować Twojej transakcji.'),
      t('Produkt jest w trakcie aktualizacji. Spróbuj ponownie w późniejszym terminie.')
    ]
    const messageToShow: string = messageContent.join('<br />')
    return <ErrorCard message={messageToShow} />
  }

  if (isMaxNumberOfRefetch) {
    // TODO: add an error logging mechanism
    const messageContent: string[] = [
      t('Ups! Nie jesteśmy wstanie obecnie zweryfikować statusu Twoich transakcji.'),
      t('Odśwież stronę i spróbuj ponownie.')
    ]
    const maxNumberOfRefetchError: string = messageContent.join('<br />')
    return <ErrorCard message={maxNumberOfRefetchError} />
  }

  return (
    <>
      {!isReadyToPay ? <Loading /> : <>{selectPaymentMethod()}</>}
    </>
  )
}

export default TransactionsAnalyzer