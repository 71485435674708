import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next';
import CardFooter from "../../card/card-footer";
import './card-form.css';
import cardsLogo from '../../../assets/cards.png';
import cardsLogoBig from '../../../assets/cards@2x.png';
import lockIcon from '../../../assets/lock-closed.svg';
import { PaymentMethod } from '../../../services/api/payments/payments.types';
import { ArrowLeft } from '@benefit-systems/common-components';

const getOptionsForms = (t: TFunction<"translation", undefined>): payu.SecureFormOptions => ({
    placeholder: {
        number: t('Numer karty'),
        date: t('MM/RR'),
        cvv: t('CVV'),
    },
    style: {
        basic: {
            fontSize: '18px'
        }
    },
    lang: "pl"
})

const tokenType: payu.tokenType = 'MULTI';

export interface CardFormProps {
    totalAmount: number;
    payByCard: (payuToken: string) => Promise<void>
    setSelectedPaymentMethod?: React.Dispatch<React.SetStateAction<PaymentMethod>>
}

const CardForm: React.FC<CardFormProps> = ({ totalAmount, payByCard, setSelectedPaymentMethod }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(false)
    const [errors, setErrors] = useState<payu.SecureFormErrorMessage[]>([])
    const payuSdkForms: payu.PayU = useMemo(() => PayU(process.env.REACT_APP_PAYU_POSID), [])
    const secureForms: payu.SecureForms = useMemo(() => payuSdkForms.secureForms(), [payuSdkForms])
    const payuCardNumberContainerRef = useRef<HTMLDivElement>(null)

    // If setSelectedPaymentMethod exist, user can back to choose payment method view
    const isPaymentConfigurationHybrid: boolean = Boolean(setSelectedPaymentMethod)

    const handleChangePaymentMethod = () => {
        setSelectedPaymentMethod?.('HYBRID');
    }

    useEffect(() => {
        const isContainerEmpty: boolean = !Boolean(payuCardNumberContainerRef.current.innerHTML)
        if (isContainerEmpty) {
            const optionsForms = getOptionsForms(t)
            const cardNumber = secureForms.add('number', optionsForms);
            const cardDate = secureForms.add('date', optionsForms);
            const cardCvv = secureForms.add('cvv', optionsForms);

            cardNumber.render('#payu-card-number');
            cardDate.render('#payu-card-date');
            cardCvv.render('#payu-card-cvv');
        }
    }, [secureForms])


    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        setLoading(true);

        sendForm()
    }

    const sendForm = async (): Promise<void> => {
        try {
            const result = await payuSdkForms.tokenize(tokenType)

            if (result.status === 'SUCCESS') {
                const { token: payuToken } = result.body;
                await payByCard(payuToken)
            } else {
                setErrors(result.error.messages)
            }
        } catch (e) {
            // TODO: add an error logging mechanism
        } finally {
            setLoading(false);
        }
    }

    return (
        <section className="container card">
            {isPaymentConfigurationHybrid && <button className="card-back-button" onClick={handleChangePaymentMethod}><ArrowLeft width={12} height={12}/> {t('Wybierz inną metodę płatności')}</button>}
            <form className="card-form" onSubmit={handleSubmit}>
                <div className="card__item">
                    <img className="card__item__icon" width="83" height="35" srcSet={`${cardsLogoBig} 2x`} src={cardsLogo} alt="cards logo" />
                    <div className="card__item__text">
                        <h2 className="card__item__title">{t('Płatność cykliczna kartą')}<img width="18" height="18" src={lockIcon} /></h2>
                        <span className="card__item__desc">{t('Każdego miesiąca będziemy potrącali opłatę z Twojej karty')}</span>
                    </div>
                </div>

                <div className="card-form__element">
                    <label className="card-form__label">{t('Numer karty')}</label>
                    <div ref={payuCardNumberContainerRef} className="card-form__input" id="payu-card-number"></div>
                </div>
                <div className="card-form__details">
                    <div className="card-form__element card-form__element--details expiration">
                        <label className="card-form__label">{t('Data ważności')}</label>
                        <div className="card-form__input" id="payu-card-date"></div>
                    </div>
                    <div className="card-form__element card-form__element--details cvv">
                        <label className="card-form__label">{t('CVV')}</label>
                        <div className="card-form__input" id="payu-card-cvv"></div>
                    </div>
                </div>

                <button type='submit' disabled={loading} className="card-form__submit" id="tokenizeButton">
                    {`${t('Zapłać')} ${totalAmount} ${t('zł')}`}
                </button>

                {/* To remove after custom error method implementation */}
                {errors.length > 0 && <div id="responseTokenize">
                    {errors.map(error => <p>{error.message}</p>)}
                </div>}
            </form>

            <CardFooter />
        </section>
    );
}

export default CardForm